import React from 'react';
import { IonFab, IonFabButton, IonIcon } from '@ionic/react';
import { add } from 'ionicons/icons';

function FabButton() {
  return (
    <IonFab>
      <IonFabButton>
        <IonIcon id="open-modal" icon={add}></IonIcon>
      </IonFabButton>
    </IonFab>
  );
}
export default FabButton;