import React, { useState } from 'react';
import { QrReader } from 'react-qr-reader';

const Test = (props) => {
  const [data, setData] = useState('No result');
  const [startScan, setStartScan] = useState(props.test);

  return (
    <>

{      <button
        onClick={() => {
          setStartScan(!startScan);
        }}
      >
        {startScan ? "Stäng." : "Scanna QR-kod"}
      </button> }

      {startScan && (<QrReader
        constraints={{
            facingMode: 'environment'
        }}
        onResult={(result, error) => {
          if (!!result) {
            setData(result?.text);            
          }

          if (!!error) {
            console.info(error);
          }
        }}
        style={{ width: "300px" }}
      />
      )}
      <p>{data}</p>
    </>
  );
};

export default Test;